import React, { useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import { AllJobOfferType } from 'types/job-offers';

import Layout from 'components/templates/layout';
import { JobOffersNotificationType } from 'components/organisms/job-offers-notification';
import OfferMissingBanner, {
  OfferMissingBannerType,
} from 'components/organisms/offer-missing-banner';
import Pagination from 'components/organisms/pagination/pagination';
import SearchWithFilter from 'components/organisms/search-with-filter';
import {
  staticCategoty,
  staticVoivodships,
  staticWorkType,
} from 'components/organisms/search-with-filter/staticData';
import IconButton from 'components/molecules/icon-button';
import JobOfferCard from 'components/molecules/job-offer-card';
import JobOfferCardList from 'components/molecules/job-offer-card-list';
import { StyledWrapperActionSort } from 'components/molecules/job-offers-hub-styles/job-offers-hub-style.styles';
import SimpleWrapper from 'components/molecules/simple-wrapper';
import Breadcrumb from 'components/atoms/breadcrumb';
import Input from 'components/atoms/input';
import Typography from 'components/atoms/typography';

import useCurrentLanguage from 'hooks/use-current-language';
import { useScreenService } from 'hooks/use-screen-service';

type DataProps = {
  jobOffersHubPage: {
    template: {
      jobOffersData: {
        jobOffersPageElements: {
          showText: string;
          sortLabel: string;
        };
        bannerCvOffer: OfferMissingBannerType;
        jobOffersNotifications: JobOffersNotificationType;
      };
    };
  };
  allJobOfferType: AllJobOfferType;
};

type Filters = {
  category?: string[];
  voivodship?: string[];
  workmode?: string[];
  salaryfrom?: number;
  salaryto?: number;
};

export const JobOffers = ({
  pageContext,
  data: {
    jobOffersHubPage: {
      template: { jobOffersData },
    },
    allJobOfferType,
  },
}: PageProps<DataProps, PageContextType>) => {
  const postLimit = 12;
  const allPosts = allJobOfferType.edges;

  const { isMdUp } = useScreenService();
  const [showType, setShowType] = useState('grid');
  const [filteredPosts, setFilteredPosts] = useState(allPosts);
  const pageLimit = Math.ceil(filteredPosts.length / postLimit);
  const [, setSortOption] = useState('');
  const currentLanguage = useCurrentLanguage();
  const nameAZ = currentLanguage === 'PL' ? 'Nazwa A-Z' : 'Name A-Z';
  const nameZA = currentLanguage === 'PL' ? 'Nazwa Z-A' : 'Name Z-A';
  const salaryFromLowest =
    currentLanguage === 'PL' ? 'Wynagrodzenie od najniższego' : 'Salary from lowest';
  const salaryFromHighest =
    currentLanguage === 'PL' ? 'Wynagrodzenie od najwyższego' : 'Salary from highest';
  const sortText = currentLanguage === 'PL' ? 'Sortuj' : 'Sort';
  const noJobOffersFilterText =
    currentLanguage === 'PL'
      ? 'Brak ofert spełniających kryteria wyszukiwania'
      : 'No offers matching the search criteria';
  const showText = currentLanguage === 'PL' ? 'Pokazuje:' : 'Showing:';
  const jobOffersText = currentLanguage === 'PL' ? 'ofert pracy' : 'job offers';
  const showingPaginationText = currentLanguage === 'PL' ? 'Wyświetlanie' : 'Showing';
  const resultsPaginationText = currentLanguage === 'PL' ? 'rezultatów' : 'results';
  const fromPaginationText = currentLanguage === 'PL' ? 'z' : 'from';
  const sortingOptions = [
    { value: 'name-asc', label: nameAZ },
    { value: 'name-desc', label: nameZA },
    { value: 'salary-asc', label: salaryFromLowest },
    { value: 'salary-desc', label: salaryFromHighest },
  ];

  const defaultCurrentShowPost = {
    from: 0,
    to: postLimit,
    currentPage: 1,
  };

  const [currentShowPost, setCurrentShowPost] = useState(defaultCurrentShowPost);
  const sortPosts = (option) => {
    let sortedPosts;
    switch (option) {
      case 'name-asc':
        sortedPosts = [...filteredPosts].sort((a, b) => a.node.title.localeCompare(b.node.title));
        break;
      case 'name-desc':
        sortedPosts = [...filteredPosts].sort((a, b) => b.node.title.localeCompare(a.node.title));
        break;
      case 'salary-asc':
        sortedPosts = [...filteredPosts].sort(
          (a, b) => a.node.offersData.salaryfrom - b.node.offersData.salaryfrom
        );
        break;
      case 'salary-desc':
        sortedPosts = [...filteredPosts].sort(
          (a, b) => b.node.offersData.salaryfrom - a.node.offersData.salaryfrom
        );
        break;
      default:
        sortedPosts = filteredPosts;
    }
    setFilteredPosts(sortedPosts);
  };
  const handleChange = (e) => {
    const selectedValue = e.value;
    setSortOption(selectedValue);
    sortPosts(selectedValue);
  };

  const handleSearch = (query: string, filters: Filters) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const urlFilters = extractFiltersFromUrl(); // Get filters from URL
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    filters = { ...filters, ...urlFilters }; // Merge filters from URL with other filters

    let filtered = allPosts.filter((offer) =>
      offer.node.title.toLowerCase().includes(query.toLowerCase())
    );

    if (filters.category && filters.category.length > 0) {
      filtered = filtered.filter(
        // @ts-ignore
        (offer) => filters.category.includes(offer.node.offersData.category)
      );
    }

    if (filters.voivodship && filters.voivodship.length > 0) {
      // @ts-ignore

      filtered = filtered.filter(
        // @ts-ignore
        (offer) => filters.voivodship.includes(offer.node.offersData.voivodship)
      );
    }

    if (filters.workmode) {
      let workmodeArray;
      // if filters.workmode is a string, make it an array
      if (typeof filters.workmode === 'string') {
        workmodeArray = [filters.workmode];
      } else {
        workmodeArray = filters.workmode;
      }

      filtered = filtered.filter((offer) => {
        if (!offer.node.offersData.workmode) return false;

        if (Array.isArray(offer.node.offersData.workmode)) {
          return workmodeArray.some((type) => offer.node.offersData.workmode.includes(type));
        }
        const types = offer.node.offersData.workmode.split(',').map((type) => type.trim());
        return workmodeArray.some((type) => types.includes(type));
      });
    }

    setFilteredPosts(filtered);
    setCurrentShowPost(defaultCurrentShowPost);
  };

  const extractFiltersFromUrl = () => {
    const url = window.location.href;
    const hashIndex = url.indexOf('#');
    if (hashIndex === -1) return {};

    const filterString = url.substr(hashIndex + 1);
    const filterPairs = filterString.split('&');
    const filters = {};
    filterPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      // Only add filters that have a value
      if (value && value.length > 0) {
        if (key === 'salaryfrom' || key === 'salaryto') {
          filters[key] = Number(decodeURIComponent(value));
        } else {
          filters[key] = value.split(',');
        }
      }
    });
    return filters;
  };

  const getOptions = (property: string): string[] => {
    const options: Set<string> = new Set();
    allJobOfferType.edges.forEach((offer) => {
      let items: string[];
      if (property === 'workmode') {
        if (typeof offer.node.offersData[property] === 'string') {
          items = offer.node.offersData[property].split(',');
        } else if (Array.isArray(offer.node.offersData[property])) {
          items = offer.node.offersData[property] as unknown as string[];
        } else {
          return; // If workmode is neither a string nor an array, skip this iteration
        }
        items.forEach((item) => {
          options.add(item.trim());
        });
      } else if (offer.node.offersData[property]) {
        options.add(offer.node.offersData[property]);
      }
    });
    return Array.from(options);
  };

  const dynamicWorkmode = getOptions('workmode');
  const mergedWorkmode = [
    ...staticWorkType,
    ...dynamicWorkmode.filter((item) => !staticWorkType.includes(item)),
  ];
  const dynamicVoivodships = getOptions('voivodship');
  const mergedVoivodship = [
    ...staticVoivodships,
    ...dynamicVoivodships.filter((item) => !staticVoivodships.includes(item)),
  ];
  const dynamicCategory = getOptions('category');
  const mergedCategory = [
    ...staticCategoty,
    ...dynamicCategory.filter((item) => !staticCategoty.includes(item)),
  ];

  const filterMaps = {
    category: mergedCategory.reduce(
      (acc, cur, index) => ({
        ...acc,
        [cur]: index,
      }),
      {}
    ),
    voivodship: mergedVoivodship.reduce(
      (acc, cur, index) => ({
        ...acc,
        [cur]: index,
      }),
      {}
    ),
    workmode: mergedWorkmode.reduce(
      (acc, cur, index) => ({
        ...acc,
        [cur]: index,
      }),
      {}
    ),
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // Extract search parameter from URL
    const searchQuery = params.get('search') || '';

    const filters = {};
    Array.from(params.entries()).forEach(([key, value]) => {
      // Ignore the search parameter in this loop
      if (key !== 'search') {
        if (filters[key]) {
          if (!Array.isArray(filters[key])) {
            filters[key] = [filters[key]];
          }
          filters[key].push(decodeURIComponent(value));
        } else {
          filters[key] = decodeURIComponent(value);
        }
      }
    });

    Object.keys(filters).forEach((key) => {
      // Only process keys that exist in filterMaps
      if (filterMaps[key]) {
        if (Array.isArray(filters[key])) {
          filters[key] = filters[key].map(
            (index) => Object.keys(filterMaps[key])[parseInt(index, 10)]
          );
        } else {
          filters[key] = Object.keys(filterMaps[key])[parseInt(filters[key], 10)];
        }
      }
    });

    // Use the extracted search parameter
    handleSearch(searchQuery, filters);
  }, []);

  const startPost = (currentShowPost.currentPage - 1) * postLimit;
  const endPost = startPost + postLimit;

  const listOffers = filteredPosts
    .slice(startPost, endPost)
    .map((offer, index) => <JobOfferCardList key={index} {...offer.node} />);
  const cardOffers = filteredPosts
    .slice(startPost, endPost)
    .map((offer, index) => <JobOfferCard key={index} {...offer.node} />);

  return (
    <Layout {...{ pageContext }}>
      <SimpleWrapper
        bgColor={theme.colors.complementary1}
        padding={`${theme.gap.md} 0 120px 0 `}
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumb {...{ pageContext }} />
      </SimpleWrapper>
      <SearchWithFilter
        allJobOfferType={allJobOfferType}
        bgColor={theme.colors.white}
        margin="-87px 0 0  0"
        maxWidth={theme.wrapper}
        onSearch={handleSearch}
        pageContext={pageContext}
        typeOffers="main"
      />
      <SimpleWrapper
        justifyContent="space-between"
        flexDirection={!isMdUp ? 'column' : 'row'}
        bgColor="#ECEFF4"
        padding="0 16px 16px 16px"
        mobilePadding="0 16px"
        alignItems={isMdUp ? 'center' : 'flex-start'}
      >
        {filteredPosts.length > 0 ? (
          <Typography
            variant="title3"
            html={`${showText} ${filteredPosts.length} ${jobOffersText}`}
          />
        ) : (
          <Typography variant="title3" html={noJobOffersFilterText} />
        )}

        {isMdUp ? (
          <SimpleWrapper padding="0" gap="15px">
            <StyledWrapperActionSort>
              <IconButton
                name="grid"
                active={showType === 'grid'}
                onClick={() => setShowType('grid')}
              />
              <IconButton
                name="list"
                active={showType === 'list'}
                onClick={() => setShowType('list')}
              />
              <Input
                type="select"
                placeholder={sortText}
                name="nazwa-pola"
                options={sortingOptions}
                handleChange={handleChange}
              />
            </StyledWrapperActionSort>
          </SimpleWrapper>
        ) : (
          <StyledWrapperActionSort>
            <Input
              type="select"
              placeholder={sortText}
              name="nazwa-pola"
              options={sortingOptions}
              handleChange={handleChange}
            />
          </StyledWrapperActionSort>
        )}
      </SimpleWrapper>

      <SimpleWrapper
        flexDirection={showType === 'list' ? 'column' : 'row'}
        gapMobile="8px"
        gap="16px"
        bgColor="#ECEFF4"
      >
        {showType === 'list' && isMdUp ? listOffers : cardOffers}
      </SimpleWrapper>
      <Pagination
        text={{
          firstText: showingPaginationText,
          lastText: resultsPaginationText,
          middleText: fromPaginationText,
        }}
        data={{
          ...currentShowPost,
          allItem: filteredPosts.length,
          itemLimit: postLimit,
          pageLimit,
        }}
        getData={(newData) => setCurrentShowPost(newData)}
      />

      <OfferMissingBanner {...jobOffersData?.bannerCvOffer} />
    </Layout>
  );
};

export const query = graphql`
  query JobOffersHubPage($id: String, $commonComponentsSlug: String, $offerType: String) {
    jobOffersHubPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_JobOffers {
          templateName
          jobOffersData {
            ...WpJobOffersFragment
          }
        }
        ... on WpTemplate_WorkInHrk {
          templateName
          workInHrkData {
            ...WpJobOffersHrkFragment
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offerTypes: { nodes: { elemMatch: { name: { eq: $offerType } } } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
            workmode
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        pagesWithPagination {
          filterAll
          pagination {
            firstText
            lastText
            middleText
          }
        }
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default JobOffers;
